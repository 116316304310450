import axios from 'axios'

/**
 * Adjusts the font size of the given text element to fit within specified dimensions.
 * @param {SVGTextElement} textElement - The SVG text element.
 * @param {number} maxWidth - The maximum width the text can occupy.
 * @param {number} maxHeight - The maximum height the text can occupy.
 */
export function adjustFontSize(textElement, maxWidth, maxHeight) {
  // Start with a font size of 14
  let fontSize = 14;

  // Set the initial font size
  textElement.attr('font-size', fontSize);

  // Get the bounding box of the text and check if it fits within the given width and height
  let bbox = textElement.node().getBBox();
  let textWidth = bbox.width;
  let textHeight = bbox.height;

  // Keep shrinking the font size until the text fits within the maxWidth and maxHeight
  while ((textWidth > maxWidth || textHeight > maxHeight) && fontSize > 1) {
    fontSize -= 1;
    textElement.attr('font-size', fontSize);

    // Update the bounding box after changing the font size
    bbox = textElement.node().getBBox();
    textWidth = bbox.width;
    textHeight = bbox.height;
  }

  // Final font size is set once it fits
  textElement.attr('font-size', fontSize);
}

/**
 * Measures the width and height of a text string given a font size and family.
 * @param {string} text - The text to measure.
 * @param {number} fontSize - The font size to use.
 * @param {string} fontFamily - The font family to use.
 * @returns {Object} - An object with width and height properties.
 */
export function measureText(text, fontSize='14px', fontFamily='Roboto') {
  // Create a dummy SVG element
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  
  // Apply styles to the text element
  textElement.setAttribute('x', '0');
  textElement.setAttribute('y', '0');
  textElement.setAttribute('font-size', fontSize);
  textElement.setAttribute('font-family', fontFamily);
  textElement.textContent = text;
  
  // Append text to the SVG
  svg.appendChild(textElement);
  document.body.appendChild(svg);
  
  // Get dimensions
  const bbox = textElement.getBBox();
  
  // Remove the dummy SVG element
  document.body.removeChild(svg);
  
  return { wordWidth: bbox.width, wordHeight: bbox.height };
}

/**
 * Gets the transformation coordinates from a given SVG element.
 * @param {SVGElement} element - The SVG element to retrieve transformation from.
 * @returns {Object} - An object with x and y coordinates.
 */
export function getTransformXY(element) {
    const transform = element.getAttribute("transform");
    const translateMatch = transform.match(/translate\(\s*([\d.-]+)\s*,\s*([\d.-]+)\s*\)/);
    
    if (translateMatch) {
        return {
            x: parseFloat(translateMatch[1]),
            y: parseFloat(translateMatch[2]),
        };
    }
    return { x: 0, y: 0 }; // Default if no translation is found
}


/**
 * Escapes special characters in a CSS selector.
 * @param {string} selector - The CSS selector to escape.
 * @returns {string} - The escaped CSS selector.
 */
//only needed when using text as id
export function escapeCSSSelector(selector) {
  const charactersToReplace = ['.', '#', ':', '@', '/', '&', '(', ')', '[', ']', '{', '}', '=', '+'];

  // Replace each problematic character with a space
  charactersToReplace.forEach(char => {
      selector = selector.replaceAll(char, ' ');
  });

  // Remove all spaces
  selector = selector.replace(/\s/g, '');

  // Finally, escape the cleaned selector
  return CSS.escape(selector);
}


/**
 * Calculates the middle point (centroid) of an array of points.
 * @param {Array<{x: number, y: number}>} points - An array of points with x and y coordinates.
 * @returns {{x: number, y: number}} - The middle point (centroid) of the provided points.
 */
export function getMiddlePoint(vertices) {
    let area = 0;
    let centroidX = 0;
    let centroidY = 0;

    const numVertices = vertices.length;

    for (let i = 0; i < numVertices; i++) {
        const x0 = vertices[i][0];
        const y0 = vertices[i][1];
        const x1 = vertices[(i + 1) % numVertices][0];
        const y1 = vertices[(i + 1) % numVertices][1];

        const crossProduct = (x0 * y1 - x1 * y0);
        area += crossProduct;
        centroidX += (x0 + x1) * crossProduct;
        centroidY += (y0 + y1) * crossProduct;
    }

    area *= 0.5;
    centroidX /= (6 * area);
    centroidY /= (6 * area);

    return { x: centroidX, y: centroidY };
}


/**
 * Check if a point is inside a polygon using ray-casting.
 * @param {Object} point - The point to check (e.g., { x: number, y: number }).
 * @param {Array} polygon - Array of points defining the polygon (e.g., [{ x, y }, { x, y }, ...]).
 * @returns {boolean} - True if the point is inside the polygon, false otherwise.
 */
export function isPointInsidePolygon(point, polygon) {
  let intersections = 0;
  for (let i = 0; i < polygon.length; i++) {
    const vertex1 = polygon[i];
    const vertex2 = polygon[(i + 1) % polygon.length];
    
    // Check if point is within the y bounds of the edge
    if ((vertex1.y > point.y) !== (vertex2.y > point.y)) {
      const slope = (vertex2.x - vertex1.x) / (vertex2.y - vertex1.y);
      const intersectX = vertex1.x + slope * (point.y - vertex1.y);
      if (point.x < intersectX) intersections++;
    }
  }
  return intersections % 2 !== 0;
}

/**
 * Find the nearest point inside the polygon to a given point outside it.
 * This implementation nudges the point towards the polygon center.
 * @param {Object} point - The starting point outside the polygon.
 * @param {Array} polygon - Array of points defining the polygon.
 * @returns {Object} - The closest point inside the polygon.
 */
export function findNearestInsidePoint(point, polygon, centroid) {
  // Calculate the polygon's centroid
  //TODO normalize. polygon is not normalized
  console.log(point, polygon, centroid)

  let nearestPoint = { ...point };
  const maxIterations = 100;
  const stepSize = 1; // You can adjust this for finer movement

  for (let i = 0; i < maxIterations; i++) {
    if (isPointInsidePolygon(nearestPoint, polygon)) {
      return nearestPoint;
    }

    // Move the point slightly towards the centroid
    nearestPoint.x += (centroid.x - nearestPoint.x) * stepSize * 0.01;
    nearestPoint.y += (centroid.y - nearestPoint.y) * stepSize * 0.01;
  }

  // Return the closest point found within max iterations, or original point if not found
  return nearestPoint;
}

export function normalizeTextCoordinates(x, y, selectedImage) {
    const image = document.getElementById("image-space") // Reference to image element

    const originalWidth = selectedImage.width;
    const originalHeight = selectedImage.height;
    const displayedWidth = image.offsetWidth;
    const displayedHeight = image.offsetHeight;
    const scaleX = displayedWidth / originalWidth;
    const scaleY = displayedHeight / originalHeight;

    return {x: x * scaleX, y: y * scaleY};
}

export function getScaleValue(element) {
    const transform = element.style.transform
    const scaleMatch = transform.match(/scale\(([^)]+)\)/);
    if (scaleMatch) {
        return parseFloat(scaleMatch[1]);
    }

    return 1; 
}

export function setWord(points, label, wordId, image) {
  const word = { text: label, "id": wordId }
  const middlePoint = getMiddlePoint(points);
  const coords = normalizeTextCoordinates(middlePoint.x, middlePoint.y, image)
  const { wordWidth, wordHeight } = measureText(word.text);
  word.x = coords.x - wordWidth / 2;
  word.y = coords.y - wordHeight / 2;
  word.wordWidth = wordWidth;
  word.wordHeight = wordHeight;
  //we set the word manually, so no need for computation of middlepoint etc. also helps when loading
  word.isNew = false;
  return word;
}

export function logging(data) {
      axios.post(process.env.VUE_APP_BASE_URL + 'log-data', JSON.stringify({"data": data}), {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .catch(error => {
        console.error('Error logging:', error);
      });
}