<template>
  <v-container class="image-menu">
    <v-row>
      <v-col>
      <label class="btn btn-light btn-sm">
        <input type="file" id="fileinput" name="fileinput" accept="image/*" @change="handleFileUpload">
        <i class="fa fa-cloud-upload"></i> Upload Image
      </label>
      </v-col>
      <v-col>
      <v-select
        v-model="selectedOption"
        :items="dropdownOptions"
        label="Filter by"
        @change="handleDropdownChange"
        class="ml-3"
        underlined
      ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-menu
          v-model="showSuggestions"
          :close-on-content-click="false"
          max-width="300"
          offset-y
          attach
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search annotations"
              single-line
              hide-details
              v-bind="attrs"
              v-on="on"
              @input="fetchSuggestions"
            ></v-text-field>
          </template>
          <v-list v-if="suggestions.length" dense>
            <v-list-item
              v-for="suggestion in suggestions"
              :key="suggestion"
              @click="selectSuggestion(suggestion)"
            >
              <v-list-item-content>{{ suggestion }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="image-grid">
      <v-col
        v-for="image in filteredImages"
        :key="image.id"
      >
        <image-item
          :image="image"
          :selectedImage="selectedImage"
          @image-selected="selectImage"
        />

      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import axios from 'axios'
import { mapState } from 'vuex';
import { logging } from "./textHelper.js";
import ImageItem from './ImageItem.vue';
export default {
  props: ['images'],
  components: {
    ImageItem,
  },  
  data() {
    return {
      search: '',
      selectedImage: null,
      suggestions: [],
      showSuggestions: false,
      vocab: [],
      dropdownOptions: ["Annotations"],  // Options for the dropdown
      selectedOption: "Annotations", // Selected dropdown value
    };
  },
  mounted() {
    // Initialize vocab when component is mounted
    this.updateVocabBasedOnSelection(this.images, this.selectedOption);
    this.fetchDropdownOptions();
  },  
  computed: {
    ...mapState(['loggingMode']),
    filteredImages() {
      if (!this.search) {
        return this.images;
      }
      //this is potential slow
      const searchLower = this.search.toLowerCase()
      return this.images.filter(image => {
        const annotations = image[this.selectedOption];
        
        if (Array.isArray(annotations)) {
          // If annotations is an array, check if any annotation matches the search
          return annotations.some(annotation =>
            annotation.toLowerCase().includes(searchLower)
          );
        } else if (annotations && typeof annotations === 'string') {
          // If annotations is a single value, check if it matches the search
          return annotations.toLowerCase().includes(searchLower);
        }
        
        // If annotations is not an array or string, exclude the image from the results
        return false;
      });
    },
  },
  methods: {
    selectImage(image) {
      if (this.loggingMode) {
        logging({"info": "selectImage", "selectedImage": image})
      }
      this.selectedImage = image; // Update the selected image
      this.$emit('image-selected', image);
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        this.imageFile = file;
        this.uploadImage(file);
      } else {
        alert('Please upload a valid image file.');
      }
    },
    uploadImage(file) {
      const formData = new FormData();
      formData.append('file', file);
      
      axios.post(process.env.VUE_APP_BASE_URL + 'upload-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        // Assuming the server responds with the image data including its path
        const { path, width, height } = response.data;
        this.selectedImage = {
          id: Date.now(),
          name: file.name,
          path: path, // The path returned from the server
          isUpload: true,
          width: width,
          height: height
        };
        this.selectImage(this.selectedImage);
      })
      .catch(error => {
        console.error('Error uploading image:', error);
      });
    },
    fetchSuggestions() {
      if (!this.search) {
        this.suggestions = [];
        this.showSuggestions = false;
        return;
      }
      const searchLower = this.search.toLowerCase();
      const suggestions = [];
      // Limit suggestions for performance
      for (let i = 0; i < this.vocab.length && this.suggestions.length < 10; i++) {
        if (this.vocab[i].toLowerCase().includes(searchLower)) {
          suggestions.push(this.vocab[i]);
        }
      }
      this.suggestions = suggestions;
      this.showSuggestions = this.suggestions.length > 0;
      if (this.loggingMode) {
        logging({"info": "search", "searchInput": this.search})
      }      
    },
    selectSuggestion(suggestion) {
      if (this.loggingMode) {
        logging({"info": "selectSuggestion", "selectedSuggestion": suggestion})
      }
      this.search = suggestion;
      this.suggestions = [];
      this.showSuggestions = false;
    },  
    fetchDropdownOptions() {
      axios.get(process.env.VUE_APP_BASE_URL + 'get_metadata_keys')
        .then(response => {
          this.dropdownOptions = response.data;
        })
        .catch(error => {
          console.error('Error fetching dropdown options:', error);
        });
    },
    handleDropdownChange(value) {
      if (this.loggingMode) {
        logging({"info": "dropdownChange", "selectedOption": value})
      }
      //clear search
      this.search = ""
      this.selectedOption = value;
      this.updateVocabBasedOnSelection(this.images, value);
    },
    updateVocabBasedOnSelection(images, selectedOption) {
      const vocabSet = new Set();
      images.forEach(image => {
        const annotations = image[selectedOption];
        
        if (Array.isArray(annotations)) {
          // If it's an array, iterate over each annotation
          annotations.forEach(annotation => {
            vocabSet.add(annotation);
          });
        } else if (annotations) {
          vocabSet.add(annotations);
        }
      });
      this.vocab = Array.from(vocabSet).sort(); // Create a sorted list of unique annotations
    },    
  },
  watch: {
    images: {
      handler(newImages) {
        this.updateVocabBasedOnSelection(newImages, this.selectedOption);
      },
      deep: true,
    }
  }  
};


</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css');

.image-menu {
  height: 95vh; /* Full viewport height */
  border: 2px solid #333; /* Solid border with color */
  border-radius: 10px; /* Smooth rounded corners */
  padding: 10px; /* Padding inside the menu */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
  background-color: #fff; /* Optional background color */
  overflow-y: auto; /* Scrollable content when overflowing */
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.v-menu__content {
  z-index: 1000; /* Ensure dropdown appears above other elements */
}


input[type="file"] {
  display: none;
}
</style>
