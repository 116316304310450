<template>
  <div id="image-view" class="image-view" ref="imageView" @drop="onDrop"  @dragover="onDragOver" 
    @mousemove="handleDrag" @wheel="handleZoom" @mousedown="handleMouseDown" @mouseup="handleMouseUp">
    <img id="image-space" ref="image" :src="parsePath(selectedImage)" :alt="selectedImage.name" 
    @load="onImageLoad" v-if="selectedImage.path" class="image" />
    <svg class="svg-overlay" ref="svg" v-if="selectedImage.path" @mousedown="startRectangle" 
    @mouseup="endRectangle">
      <rect v-if="drawing" :x="rectX" :y="rectY" :width="rectWidth" :height="rectHeight" stroke="blue" fill="none" stroke-width="2"/>
    </svg>
    <div class="mode-buttons">
      <button
        class="mode-button"
        :class="{ active: isCurrentMode('drawRectangle') }"
        @click="setMode('drawRectangle')"
        title="Draw Box Prompt"
      ><RectangleIcon />
      </button>
      <button
        class="mode-button"
        :class="{ active: isCurrentMode('drawPoint') }"
        @click="setMode('drawPoint')"
        title="Draw Point Prompt"
      ><PointIcon />
      </button>
    </div>    
    <v-dialog v-model="dialogVisible" max-width="500px">
      <v-card>
        <v-card-title>
          Bounding Box for Segmentation Area
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="clearRectData">Close</v-btn>
          <v-btn color="blue darken-1" text @click="sendRectangleData">Send Data</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDeleteVisible" max-width="500px">
      <v-card>
        <v-card-title>
          Confirm Deletion
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete this polygon?
        </v-card-text>
        <img :src="imageToBeDeleted" style="max-width: 100%; max-height: 600px; height: auto;" />
        <v-card-actions>
          <v-btn color="red darken-1" text @click="confirmDeletePolygon">Delete</v-btn>
          <v-btn color="blue darken-1" text @click="cancelDeletePolygon">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
    <v-dialog v-model="confirmDeletePointVisible" max-width="500px">
      <v-card>
        <v-card-title>
          Confirm Deletion
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete this point?
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="confirmDeletePoint">Delete</v-btn>
          <v-btn color="blue darken-1" text @click="cancelDeletePoint">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>        
  </div>

</template>

<script>
import { mapState, mapActions } from 'vuex';
import * as d3 from 'd3';
import axios from 'axios'
import simplify from 'simplify-js';
//import d3Simplify from '../plugins/d3Simplify';
import * as turf from '@turf/turf';
import RectangleIcon from "./RectangleIcon.vue";
import PointIcon from "./PointIcon.vue";
import { logging, setWord, adjustFontSize, measureText, getMiddlePoint, normalizeTextCoordinates, 
/*isPointInsidePolygon, findNearestInsidePoint*/ } from "./textHelper.js";

export default {
  components: {
    RectangleIcon,
    PointIcon,
  },
  data() {
    return {
      resizingObserver: null,
      drawing: false,
      isPanning: false,
      moved: false,
      panningX: 0,
      panningY: 0,
      rectStartX: 0,
      rectStartY: 0,
      rectWidth: 0,
      rectHeight: 0,
      rectEndX: 0,
      rectEndY: 0,
      rectX: 0,
      rectY: 0,
      scale: 1,
      panX: 0,
      panY: 0,
      translateX: 0,
      translateY: 0,
      currentMode: null,
      minScale: 1, // Minimum zoom level
      maxScale: 5,   // Maximum zoom level
      rectData: [],
      //promptPoints: [],
      dialogVisible: false,
      currentImageSize: { width: null, height: null },
      colors: ["#a6cee3","#1f78b4","#b2df8a","#33a02c","#fb9a99","#e31a1c","#fdbf6f","#ff7f00","#cab2d6","#6a3d9a","#ffff99","#b15928","#8dd3c7","#ffffb3","#bebada","#fb8072","#80b1d3","#fdb462","#b3de69","#fccde5","#bc80bd","#ccebc5","#ffed6f"],
      usedColors: {}, // Keeps track of which color has been used     
      confirmDeleteVisible: false,
      confirmDeletePointVisible: false,
      polygonToDelete: null, // Store the polygon index to be deleted
      pointToDelete: null,
      imageToBeDeleted: null
    }
  },
  mounted() {
    //prevent right click contextmenu
    const container = d3.select(this.$refs.imageView);
    container.on("contextmenu", function(event) {
      event.preventDefault();
    });
  },  
  computed: {
    ...mapState(['selectedImage', 'loggingMode', 'words', 'isFiltered', 'pointPrompts', 'togglePoints', 'drawnWords', 'savedMasks','draggedWord', 'polygons', 'filterRange'])
  },
  methods: {
    ...mapActions(['setLoading', 'toggleFilter', 'updateWord', 'removeWord', 'saveMask', 'addDrawWord', 'updatePolygonPoints', 'addPointPrompts', 'clearPointPrompts', 'removeMask','setPolygons', 'setDrawnWords', 'addPolygon', 'updatePolygon', 'removePolygon']),
    deletePolygon(event, d) {
      if (this.moved) {
        // Prevent the click event after panning
        event.stopImmediatePropagation();
        return;
      }
      this.setLoading(true);
      axios.post(process.env.VUE_APP_BASE_URL + 'get_cutout_image', JSON.stringify({ "path": this.selectedImage["path"], 
        "points": this.polygons[d.originalIndex]["points"]}), {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(response => {
        this.setLoading(false);
        this.imageToBeDeleted = response.data.path
        this.polygonToDelete = d.index;
        this.confirmDeleteVisible = true;
      })
      .catch(error => {
        this.setLoading(false);
        console.error('Error calling get cutout', error);
      });
    },
    deletePoint(event, d) {
      if (this.moved || ! this.togglePoints) {
        // Prevent the click event after panning
        event.stopImmediatePropagation();
        return;
      }
      // Store the point to be deleted and show the confirmation dialog
      this.pointToDelete = d;
      this.confirmDeletePointVisible = true;
    },    
    onImageLoad() {
      this.panningX = 0;
      this.panningY = 0;
      this.panX = 0;
      this.panY = 0;
      d3.select(this.$refs.svg).selectAll('polygon, circle, rect, text, path').remove(); 
      this.resetZoom()
      this.clearPointPrompts()
      this.setDrawnWords([])
      this.setPolygons([])

      this.updateSVGSize();
      /*if (this.$refs.image) {
        this.resizingObserver = new ResizeObserver(() => {
          this.updateSVGSize();
        });
        this.resizingObserver.observe(this.$refs.image);
      }*/

      //load saved masks
      this.loadSavedMasks()
    },
    confirmDeletePolygon() {
      // Actually delete the polygon if confirmed
      if (this.polygonToDelete !== null) {
        const mask = this.savedMasks.find(x => x.hitIndex === this.polygonToDelete)
        // if labeled
        if (mask) {
          const wordId = mask.wordId
          // delete label
          d3.select("#group-" + wordId).remove()
          // delte mask from saved mask
          this.removeMask(mask)
          this.removeWord(wordId)
        }

        if (this.loggingMode) {
          logging({"info": "deletePolygon", "mask": mask, "polygonToDelete": this.polygonToDelete})
        }

        this.removePolygon(this.polygonToDelete)
        d3.select(`#polygon-${this.polygonToDelete}`).remove();
        d3.selectAll(`.polygon-${this.polygonToDelete}`).remove();

        this.polygonToDelete = null;
        this.confirmDeleteVisible = false;

      }
    },
    confirmDeletePoint() {
      let vm = this;
      // Actually delete the point if confirmed
      if (this.pointToDelete !== null) {
        let polygonIndex = this.pointToDelete.polygonIndex
        let points = this.pointToDelete.polygon.points
        //index and id are not always the same, because of changes
        const index = this.pointToDelete.polygon.points.findIndex(p => p[2] === this.pointToDelete.id);
        if (this.loggingMode) {
          logging({"info": "deletePoint", "point": points[index], "polygonToDelete": polygonIndex})
        }

        points.splice(index, 1); 
        //not enough points
        if (points.length < 3) {
          this.removePolygon(polygonIndex);
          d3.select(`#polygon-${polygonIndex}`).remove();
          d3.selectAll(`.polygon-${polygonIndex}`).remove();
          vm.updatePolygonPoints({polygonIndex, points})
        } else {
          vm.updatePolygonPoints({polygonIndex, points})
          d3.select(`#polygon-${polygonIndex}-${this.pointToDelete.id}`).remove();
          vm.updateSinglePolygon(vm.polygons[polygonIndex], polygonIndex);          
        }

        this.pointToDelete = null;
        this.confirmDeletePointVisible = false;
      }
    },    
    cancelDeletePolygon() {
      // Reset the polygonToDelete and hide the dialog
      this.polygonToDelete = null;
      this.confirmDeleteVisible = false;
    },
    cancelDeletePoint() {
      // Reset the polygonToDelete and hide the dialog
      this.pointToDelete = null;
      this.confirmDeletePointVisible = false;
    },  
    handleMouseDown(event) {
      this.isPanning = true;
      this.moved = false;
      this.panningX = event.clientX;
      this.panningY = event.clientY;
    },
    handleMouseUp() {
      this.panningX = 0;
      this.panningY = 0;
      this.isPanning = false;
    },
    handleZoom(event) {
      event.preventDefault();
      const zoomIntensity = 0.1;
      const image = this.$refs.image;
      const svg = this.$refs.svg;

      // Determine the scale direction (zoom in or out)
      const wheel = event.deltaY < 0 ? 1 : -1;
      let newScale = this.scale + wheel * zoomIntensity;

      // Constrain the scale to min and max values
      newScale = Math.max(this.minScale, Math.min(newScale, this.maxScale));
      if (newScale === this.scale) {
        return
      }

      const rect = this.$refs.imageView.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      // Adjust the transform origin
      const originX = (mouseX / rect.width) * 100;
      const originY = (mouseY / rect.height) * 100;
      image.style.transformOrigin = `${originX}% ${originY}%`;
      svg.style.transformOrigin = `${originX}% ${originY}%`;

      // Update the scale to the new value
      this.scale = newScale;

      image.style.transform = `scale(${this.scale}) translate(${this.panX}px, ${this.panY}px)`;
      d3.select(svg).attr("transform", `scale(${this.scale}) translate(${this.panX}, ${this.panY})`);      
    },  
    resetZoom() {
      let newScale = 1;

      const translateX = 0;
      const translateY = 0;
      // Apply the transformation
      const image = this.$refs.image;
      const svg = this.$refs.svg;

      if (image !== undefined) {
        image.style.transform = `scale(${newScale}) translate(${translateX}px, ${translateY}px)`;
      }
      if (svg !== undefined) {
        d3.select(svg).attr("transform", `scale(${newScale}) translate(${translateX}, ${translateY})`)
      }
      // Update the current scale
      this.scale = newScale;
    },
    // Function to set the current mode
    setMode(mode) {
      if (this.loggingMode) {
        logging({"info": "setMode", "mode": mode})
      }

      if (this.isCurrentMode(mode)) {
        this.currentMode = null;
      } else {
        this.currentMode = mode;
      }
    }, 
    isCurrentMode(mode) {
      return this.currentMode === mode;
    },    
    handleDrag(event) {
      if (this.isCurrentMode("drawRectangle") && this.drawing) {
        this.handleRectangle(event);
      } else if (this.isPanning) {
        this.panX += event.clientX - this.panningX;
        this.panY += event.clientY - this.panningY;
        if ( event.clientX - this.panningX > 0 || event.clientY - this.panningY > 0 ) {
          this.moved = true
        }
        const image = this.$refs.image;
        const svg = this.$refs.svg;
        image.style.transform = `scale(${this.scale}) translate(${this.panX}px, ${this.panY}px)`;
        d3.select(svg).attr("transform", `scale(${this.scale}) translate(${this.panX}, ${this.panY})`)
        this.panningX = event.clientX;
        this.panningY = event.clientY;
      }
    },
    // Initialize rectangle drawing
    startRectangle(event) {
      if (this.isCurrentMode("drawRectangle") && !this.drawing) {
        this.drawing = true;
        const rect = event.currentTarget.getBoundingClientRect();
        this.rectStartX = (event.clientX - rect.left) / this.scale; // Mouse X relative to the SVG
        this.rectStartY = (event.clientY - rect.top) / this.scale;  // Mouse Y relative to the SVG

        this.rectEndX = this.rectStartX;
        this.rectEndY = this.rectStartY;
        this.rectWidth = 0;
        this.rectHeight = 0;
      } else if (this.isCurrentMode("drawPoint")) {
          const rect = event.currentTarget.getBoundingClientRect();

          const {scaleX, scaleY} = this.getScale()

          let pX = (event.clientX - rect.left) / this.scale; 
          let pY = (event.clientY - rect.top) / this.scale; 
          let typeClick = (event.buttons === 1 ? "positive" : "negative")
          this.addPointPrompts({"x": pX / scaleX, "y": pY / scaleY, "type": typeClick})
          const sym = d3.symbol().type(d3.symbolStar).size(20); 
          const svg = d3.select(this.$refs.svg);
          svg.append("path") 
              .attr("d", sym) 
              .attr("class", "point-prompt")
              .attr("stroke", (typeClick === "positive" ? "blue": "red")) 
              .attr("fill", "white") 
              .attr("stroke-width", "0.5px") 
              .attr("cursor", "pointer")
              .attr("transform", `translate(${pX}, ${pY})`)
              .on("click", function() {
                //does not work in point prompt mode because of the svg overlay
                d3.select(this).remove();
              });
      }
    },
    // Update rectangle dimensions
    handleRectangle(event) {
      if (this.isCurrentMode("drawRectangle") && this.drawing) {
        const svg = this.$refs.svg; // Access the SVG element
        const rect = svg.getBoundingClientRect(); // Get the SVG bounding rectangle
        // Update end coordinates
        this.rectEndX = (event.clientX - rect.left) / this.scale;
        this.rectEndY = (event.clientY - rect.top) / this.scale;

        // Calculate rectangle width and height
        this.rectWidth = this.rectEndX - this.rectStartX;
        this.rectHeight = this.rectEndY - this.rectStartY;

        // Calculate x and y for rectangle position
        this.rectX = Math.min(this.rectStartX, this.rectEndX);
        this.rectY = Math.min(this.rectStartY, this.rectEndY);

        // Adjust width and height for positive values
        this.rectWidth = Math.abs(this.rectWidth);
        this.rectHeight = Math.abs(this.rectHeight);
      }
    },
    // Finish drawing rectangle and send data
    endRectangle() {
      if (this.isCurrentMode("drawRectangle") && this.drawing) {
        if (this.rectWidth === 0 || this.rectHeight === 0) {
          this.drawing = false
          return
        }        
        const {scaleX, scaleY} = this.getScale()

        this.rectData = [this.rectX / scaleX, this.rectY / scaleY, (this.rectX + this.rectWidth) / scaleX, (this.rectY + this.rectHeight) / scaleY]
        this.dialogVisible = true;
      }
    },
    clearRectData() {
        this.drawing = false;
        // Reset rectangle data
        this.rectStartX = 0;
        this.rectStartY = 0;
        this.rectEndX = 0;
        this.rectEndY = 0;
        this.rectWidth = 0;
        this.rectHeight = 0;
        this.rectX = 0;
        this.rectY = 0;
        this.rectData = []

        this.dialogVisible = false;
    },
    // Send rectangle data to backend
    async sendRectangleData() {
      if (this.loggingMode) {
        logging({"info": "boxPrompt", "path": this.selectedImage["path"], 
        "bbox": this.rectData})
      }

      this.setLoading(true)
      axios.post(process.env.VUE_APP_BASE_URL + 'call_sam_with_bb', JSON.stringify({ "path": this.selectedImage["path"], 
        "bbox": this.rectData}), {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(response => {
         this.setLoading(false)
         this.drawing = false;
         this.clearRectData();
         this.addPolygon(response.data.polygons[0]);
      })
      .catch(error => {
        this.drawing = false;
        this.setLoading(false)
        console.error('Error calling SAM with BB:', error);
      });
    },       
    beforeDestroy() {
      this.cleanUpResizeObserver(); // Clean up when the component is destroyed
    },     
    onDrop(event) {
      event.preventDefault();

      const word = this.draggedWord;

      const svg = this.$refs.svg;
      const point = svg.createSVGPoint();
      point.x = event.clientX; 
      point.y = event.clientY;
      // Convert to SVG coordinates considering zoom and pan
      const svgCoords = point.matrixTransform(svg.getScreenCTM().inverse());

      // Measure the dimensions of the word
      const { wordWidth, wordHeight } = measureText(word.text);
      const x = svgCoords.x- word.offsetX;
      const y = (svgCoords.y- word.offsetY) + wordHeight;

      word.x = x;
      word.y = y;
      word.wordWidth = wordWidth;
      word.wordHeight = wordHeight;

      if (this.loggingMode) {
        logging({"info": "droppedWord", "word": word})
      }

      this.addDrawWord(word)
      this.drawWords()
    },  
    drawWords() {
      d3.selectAll(".word-group").remove()
      for (let word of this.drawnWords) {
        this.drawWord(word);
      }
    },
    drawWord(word) {
      const svg = d3.select(this.$refs.svg); 
      // Group the rect and text elements together
      let g = svg.append("g")
        .attr("id", `group-${word.id}`)
        .attr("class", "word-group")
        .attr("transform", `translate(${word.x}, ${word.y})`);

      // Append the rectangle element
      g.append('rect')
        .attr('id', `rect-${word.id}`)
        .attr('x', 0) 
        .attr('y', 0) 
        .attr('width', word.wordWidth) 
        .attr('height', word.wordHeight) 
        .attr('x', -5) 
        .attr('y', -word.wordHeight) 
        .attr('width', word.wordWidth + 10) 
        .attr('height', word.wordHeight * 2)
        .attr('fill', 'rgba(255, 255, 255)')
        .attr("fill-opacity", 0.7)
        .attr('stroke', '#ccc')
        .style("cursor", "move");

      // Append the text element
      g.append('text')
        .attr('id', `word-${word.id}`)
        .attr('class', `word-${word.text}`)
        .attr('x', 0)
        .attr('y', 0)
        .attr('text-anchor', 'start')
        .attr('font-size', '14px')
        .attr('fill', 'black')
        .text(word.text)
        .style("cursor", "move");

      g.call(d3.drag()
        .on("drag", (event) => {
          const { x: newX, y: newY } = event;
          g.attr("transform", `translate(${newX}, ${newY})`);
        })
        .on("end", (event) => {
          
          const { x: x, y: y } = event;
          d3.selectAll(`#rect-${word.id}`).style("fill", "white")
          // get hitindex from masks, than delete from masks and use the index to color the polygon back
          let mask = this.savedMasks.find(x => x.wordId === word.id)
          let hitIndex = null;
          if (mask) {
            console.log(mask["hitIndex"])
            hitIndex = mask["hitIndex"]
            this.removeMask(mask)
            d3.select(`#polygon-${hitIndex}`)
              .style("fill", "#969696")
              .style("stroke", "#525252");  
            d3.selectAll(`.polygon-${hitIndex}`)
              .style("stroke", "#525252");
          }
          word.x = x;
          word.y = y;

          if (this.loggingMode) {
            logging({"info": "dragWord", "word": word})
          }

          const { wordWidth, wordHeight } = measureText(word.text);
          const x1 = x - (wordWidth / 2) 
          const x2 = x1 + (wordWidth * 2)
          const y1 = y - wordHeight
          const y2 = y1 + (wordHeight * 2)
          word.isLoaded = false;
          this.checkIntersection(word, x1, x2, y1, y2, mask);
        })
      );

      const x1 = word.x - (word.wordWidth / 2) 
      const x2 = x1 + (word.wordWidth * 2)
      const y1 = word.y - word.wordHeight
      const y2 = y1 + (word.wordHeight * 2)

      let mask = this.savedMasks.find(x => x.wordId === word.id)
      // Check for intersection and update polygon color
      this.checkIntersection(word, x1, x2, y1, y2, mask);
    }, 
    onDragOver(event) {
      event.preventDefault();
    },
    parsePath(image) {
      return process.env.VUE_APP_BASE_URL + image.path;
    },
    updateSVGSize() {
      const image = this.$refs.image;
      const svg = d3.select(this.$refs.svg);
      if (image && svg.node()) {
        svg.attr('width', image.offsetWidth)
          .attr('height', image.offsetHeight)
          .attr("transform", `scale(${this.scale}) translate(${this.panX}, ${this.panY})`);
        //console.log("update size")
        //this.drawPolygons(); // Redraw polygons after updating SVG size
        //this.drawWords()
      }
    },
    cleanUpResizeObserver() {
      if (this.resizingObserver) {
        this.resizingObserver.disconnect();
      }
    },
    simplifyPolygon(points, tolerance = 5) {
      return simplify(points.map((p, i) => ({ x: p[0], y: p[1], id: i })), tolerance, true)
        .map(p => [p.x, p.y, p.id]);
    },
    updateSinglePolygon(polygon, polygonIndex) {
      const {scaleX, scaleY} = this.getScale()
      const scaledPoints = polygon.points.map(p => [p[0] * scaleX, p[1] * scaleY].join(",")).join(" ");
      d3.select(this.$refs.svg).select(`#polygon-${polygonIndex}`)
        .attr("points", scaledPoints);
      //save this information for re-scaling -> creates point duplicates?
      this.updatePolygon({ polygonIndex, polygon });
    },       
    drawPolygons() {
      let vm = this;
      d3.select(this.$refs.svg).selectAll('polygon, circle').remove(); 
      if (this.polygons.length === 0) {
        return
      }
      console.log("drawPolygons")
      const {scaleX, scaleY} = this.getScale()
      const image = this.$refs.image;
      const displayedWidth = image.offsetWidth;
      const displayedHeight = image.offsetHeight;

      const svg = d3.select(this.$refs.svg)
        .attr('width', displayedWidth)
        .attr('height', displayedHeight)

      const polygonsWithIndex = this.polygons.map((polygon, index) => ({
        ...polygon,
        originalIndex: index, // Preserve the original index
      }));

      // predictor polygons do not have a size
      //const filteredPolygons = this.polygons.filter(polygon => polygon.points.length >= 3 && (polygon.size === undefined || (polygon.size >= this.filterRange[0] && polygon.size <= this.filterRange[1])));
      //slider filter based of index. polygons are sorted desc. minIndex how many small elements to skip. 
      const [minIndex, maxIndex] = this.filterRange;
      const filteredPolygons = polygonsWithIndex.slice(this.polygons.length - maxIndex, this.polygons.length - minIndex).filter(polygon => polygon.points.length >= 3);
      
      // Douglas–Peucker
      const simplifiedPolygons = filteredPolygons.map(polygon => ({
        ...polygon,
        points: this.simplifyPolygon(polygon.points)
      }));
      // Visvalingam’s algorithm
      /*const simplifiedPolygons = filteredPolygons.map(polygon => {
        const simplify = d3Simplify();
        const simplified = simplify(polygon.points);
        return {
          ...polygon,
          points: simplified
        };
      });*/
      const points = simplifiedPolygons.map(polygon => polygon.points.map(
        p => [
          p[0] * scaleX, 
          p[1] * scaleY].join(",")).join(" ")
      );

      svg.selectAll("polygon")
        .data(points.map((d, i) => ({ value: d, index: i })))
        .data(points.map((d, i) => ({ value: d, index: i, originalIndex: simplifiedPolygons[i].originalIndex }))) 
        .enter()
        .append("polygon")
        .attr("points", points => points.value)
        .attr("id", (d, i) => `polygon-${i}`)
        .attr("class", "polygon")
        .style("fill", "#969696")
        .style("stroke", "#525252")  
        .style("fill-opacity", 0.5)
        .style("stroke-width", 2)
        .style("cursor", "pointer")
        .on("click", (event, d) => vm.deletePolygon(event, d));

      svg.selectAll("circle")
        .data(simplifiedPolygons.flatMap((polygon, polygonIndex) => 
          polygon.points.map((point, i) => ({
            x: (point[0] * scaleX).toFixed(2),
            y: (point[1] * scaleY).toFixed(2),
            polygon,
            polygonIndex,
            id: point[2],
            index: i
          }))
        ))    
        .enter()
        .append("circle")
        .attr("cx", d => d.x)
        .attr("cy", d => d.y)
        .attr("r", 1)
        .attr("id", d => `polygon-${d.polygonIndex}-${d.id}`)
        .attr("class", d=> `point polygon-${d.polygonIndex}`)
        .style("fill", "#969696")
        .style("stroke", "#525252")  
        .style("stroke-width", 0.5)
        .style("fill-opacity", this.togglePoints ? "1" : "0")
        .style("stroke-opacity", this.togglePoints ? "1" : "0")
        .style("cursor", "pointer")
        .style("pointer-events", "auto")  // Enable pointer events
        .on("click", (event, d) => vm.deletePoint(event, d))
        .call(d3.drag()  // Adding drag behavior
          .on("drag", (event, d) => {
            const [x, y] = [event.x, event.y];
            d.x = x;
            d.y = y;
            d3.select("#polygon-" + d.polygonIndex + "-" + d.id)
              .attr("cx", d.x)
              .attr("cy", d.y);

            const point = d.polygon.points.find(p => p[2] === d.id);
            if (point) {
              point[0] = x / scaleX;
              point[1] = y  / scaleY;
              vm.updateSinglePolygon(d.polygon, d.polygonIndex);
            }
          })
          .on("end", (event, d) => {
            if (this.loggingMode) {
              logging({"info": "dragPoint", "point": [event.x, event.y], "polygon": d})
            }
          })
        );
      
      //put text on top       svg.selectAll("g").raise(); 
    },
    checkIntersection(word, x1, x2, y1, y2, oldMask=null) {
      //TODO include logging
      if (this.polygons.length === 0) {
        return
      }
      const wordId = word.id
      const {scaleX, scaleY} = this.getScale()

      let hitPolygonIndex = -1;
      let maxOverlapArea = Infinity; // Track the largest overlap area
      const wordRect = turf.polygon([[
          [x1, y1],
          [x2, y1],
          [x2, y2],
          [x1, y2],
          [x1, y1]
        ]]);

      //skip not visible polygons
      let[end, start] = this.filterRange;
      let startIndex = this.polygons.length - start;
      let endIndex = this.polygons.length - end;

      //start with the smallest
      for (let index = startIndex; index < endIndex; index++) {
        const polygon = this.polygons[index];
        const scaledPoints = polygon.points.map(p => [p[0] * scaleX , p[1] * scaleY]);
        // First and last position must be equivalent.
        scaledPoints.push(scaledPoints[0]);
        const turfPoly = turf.polygon([scaledPoints]);
        const intersect = turf.booleanIntersects(turfPoly, wordRect);
        if (intersect) {
          const intersection = turf.intersect(turf.featureCollection([turfPoly, wordRect]));
          //in case intersection gives null because of edge cases
          if (intersection) {
            const overlapArea = turf.area(intersection);

            //for small things we also include the size of the polygon in general
            const polyArea = turf.area(turfPoly);

            //I want the smalles diff, so that area that is consumed the most by the text label
            const diff = polyArea - overlapArea;
            if (diff < maxOverlapArea) {
              maxOverlapArea = diff;
              hitPolygonIndex = index;
            }
          }
        }
      }
      if (hitPolygonIndex !== -1) {
        const color = this.getColorForWord(word.text);
        // Update the polygon color
        d3.select(this.$refs.svg).select(`#polygon-${hitPolygonIndex}`)
          .style("fill", color)
          .style("stroke", color);

        d3.selectAll(`.polygon-${hitPolygonIndex}`)
          .style("stroke", color)  

        this.resizeWord(hitPolygonIndex, word, x1, x2, y1, y2, scaleX, scaleY)
        // Update the word color
        d3.selectAll(`#rect-${word.id}`)
          .style("fill", color)

        let oldHitIndex = null;
        if (oldMask) {
          oldHitIndex = oldMask["hitIndex"]
        }
        //only save after drag and drop into new polygon or when new
        if (!word.isLoaded && (oldHitIndex != hitPolygonIndex || word.isNew)) {
          if (this.loggingMode) {
            logging({"info": "Save new Mask", "hitIndex": hitPolygonIndex, "wordId": word.id, 
              "annotation": word.text, "image": this.selectedImage.path, "polygon": this.polygons[hitPolygonIndex]})
          }
          this.saveMask({hitIndex: hitPolygonIndex, "wordId": word.id, "annotation": word.text, "image": this.selectedImage.path, "polygon": this.polygons[hitPolygonIndex]})
        }

        //middle point only on first set
        if (oldHitIndex != hitPolygonIndex && word.isNew) {

          const polygonBounds = this.polygons[hitPolygonIndex].points;
          const middlePoint = getMiddlePoint(polygonBounds);
          const coords = normalizeTextCoordinates(middlePoint.x, middlePoint.y, this.selectedImage)
          const wordWidth = d3.select(`#rect-${word.id}`).attr("width")
          const halfWidth = wordWidth / 2;
          /*let newX = middlePoint.x - halfWidth //coords.x - halfWidth;
          let newY = middlePoint.y //coords.y;
          console.log(newX)
          const wordLeft = newX;
          const wordRight = newX + wordWidth;
          //TODO sometimes move woords avway if not needed
          // Adjust if the word goes outside the polygon bounds
          if (!isPointInsidePolygon({ x: wordLeft, y: newY }, polygonBounds)) {
            newX = findNearestInsidePoint({ x: wordLeft, y: newY }, polygonBounds, middlePoint).x;
          } else if (!isPointInsidePolygon({ x: wordRight, y: newY }, polygonBounds)) {
            newX = findNearestInsidePoint({ x: wordRight - wordWidth, y: newY }, polygonBounds, middlePoint).x;
          }
          console.log(newX)
          const coords = normalizeTextCoordinates(newX, newY, this.selectedImage)*/
          coords.x -= halfWidth

          d3.select(`#group-${word.id}`).attr("transform", `translate(${coords.x}, ${coords.y})`);
          word.x = coords.x;
          word.y = coords.y;
        } 
      }
      word.isNew = false; 
      this.updateWord({wordId, word})
    },
    resizeWord(hitPolygonIndex, word, x1, x2, y1, y2, scaleX, scaleY) {
      // Get bounding box of the polygon
      const polygonPoints = this.polygons[hitPolygonIndex].points;

      // Calculate the bounding box of the polygon by finding the min and max x, y values
      let polyMinX = Infinity, polyMinY = Infinity, polyMaxX = -Infinity, polyMaxY = -Infinity;
      polygonPoints.forEach(point => {
        const [x, y] = point;
        if (x < polyMinX) polyMinX = x;
        if (y < polyMinY) polyMinY = y;
        if (x > polyMaxX) polyMaxX = x;
        if (y > polyMaxY) polyMaxY = y;
      });

      // Get the width and height of the polygon's bounding box
      const polygonWidth = (polyMaxX * scaleX) - (polyMinX * scaleX);
      const polygonHeight = (polyMaxY * scaleY) - (polyMinY * scaleY);

      // Calculate the word's current width and height
      const wordWidth = Math.abs(x2 - x1);
      const wordHeight = Math.abs(y2 - y1);

      // Calculate the scale factor to fit the word inside the polygon
      const scaleFactor = Math.min(polygonWidth / wordWidth, polygonHeight / wordHeight, 1);

      // Adjust font size
      const textElement = d3.select(`#word-${word.id}`); 
      
      adjustFontSize(textElement, polygonWidth, polygonHeight)

      const bbox = textElement.node().getBBox();
      const textWidth = bbox.width;

      d3.select(`#rect-${word.id}`)
        .attr("width", wordWidth * scaleFactor)
        .attr("height", wordHeight * scaleFactor)
        .attr("x", -((wordWidth * scaleFactor) - textWidth) / 2)
        .attr("y", -(wordHeight * scaleFactor) * (2/3))
    },
    // Helper method to get or assign a color for a word
    getColorForWord(wordText) {
      if (!this.usedColors[wordText]) {
        const color = this.colors[Math.floor( Object.keys(this.usedColors).length % this.colors.length)];
        this.usedColors[wordText] = color;
      }
      return this.usedColors[wordText];
    },
    getScale() {
      const image = this.$refs.image;
      const originalWidth = this.selectedImage.width; // Assuming the original width is available
      const originalHeight = this.selectedImage.height; // Assuming the original height is available
      const displayedWidth = image.offsetWidth;
      const displayedHeight = image.offsetHeight;

      const scaleX = displayedWidth / originalWidth;
      const scaleY = displayedHeight / originalHeight;

      return {"scaleX": scaleX, "scaleY": scaleY};
    },
    loadSavedMasks() {
      const masks = this.savedMasks.filter(x => x["image"] === this.selectedImage["path"])
      const savedWords = []  
      for (let mask of masks) {
        let points = mask["polygon"].points;
        let word = setWord(points, mask["annotation"], mask["wordId"], this.selectedImage)
        word.isLoaded = true;
        savedWords.push(word)
      }        
      this.setDrawnWords(savedWords)
      this.setPolygons(masks.map(x => x["polygon"]))
    }
  },
  watch: {
    polygons(val, prev) {
      if (val.length !== prev.length) {
        console.log("polygons")
        this.drawPolygons()
        this.drawWords()
      }
    },
    togglePoints(isVisible) {
      const svg = d3.select(this.$refs.svg)
      svg.selectAll(".point")
        .style("fill-opacity", isVisible ? "1" : "0")
        .style("stroke-opacity", isVisible ? "1" : "0");
    },
    pointPrompts(val) {
      const svg = d3.select(this.$refs.svg)
      if (val.length === 0) {
        svg.selectAll(".point-prompt").remove()
      }
    },
    isFiltered(val, prev) {
      if (val && val !== prev) {
        this.drawPolygons()
        this.drawWords()
        this.toggleFilter();
      }
    },
  }
}
</script>

<style scoped>


.image-view {
    position: relative; /* Allows absolute positioning of the SVG */
    /*width: 100%;
    height: 100%;*/
    height: 100vh; /* Full viewport height */
    overflow: hidden; /* Hide overflow for a clean fit */
    border: black solid 1px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Image styling */
.image {
    position: absolute; /* Allows the image to be positioned relative to the container */
    max-width: 100%; /* Ensure image fits within the container */
    max-height: 100%; /* Ensure image fits within the container */
    object-fit: contain; /* Maintain aspect ratio */
    pointer-events: none; /* Disable pointer events on the image */
}

/* SVG overlay styling */
.svg-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  /*
  old
      border: red solid 1px;
    position: absolute; 
    top: 0; 
    left: 0;
    width: 100%; 
    height: 100%;
  */
}

.mode-buttons {
  position: absolute;
  top: 0px;
  right: 0px;
}

.mode-button {
  background: white;
  border: 2px solid gray;
  padding: 5px;
  margin: 2px;
  cursor: pointer;
  font-size: 20px;
}

.mode-button.active {
  background: lightgray;
}

</style>