<template>
  <v-app>
    <v-overlay id="loading-window" :value="loading" absolute>
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>
    <v-container fluid>
      <v-row>
        <v-col cols="3"> <!-- 25% width -->
          <ImageMenu :images="images" @image-selected="selectImage" />
        </v-col>
        <v-col cols="6"> <!-- 50% width -->
          <ImageView/>
        </v-col>
        <v-col cols="3"> <!-- 25% width -->
          <AnnotationList :images="images"/>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';
import ImageMenu from './components/ImageMenu.vue';
import ImageView from './components/ImageView.vue';
import AnnotationList from './components/AnnotationList.vue';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    ImageMenu,
    ImageView,
    AnnotationList,
  },
  data() {
    return {
      images: [],
    };
  },
  created() {
    console.log(process.env.VUE_APP_BASE_URL)
    this.fetchImages();
  },
  computed: {
    ...mapState(['words', 'loading'])
  },  
  methods: {   
    ...mapActions(['setWords', 'setSelectedImage']), 
    fetchImages() {
      axios.get(process.env.VUE_APP_BASE_URL + 'get_images')
        .then(response => {
          this.images = response.data;
          this.selectImage(this.images[0])
        })
        .catch(error => {
          console.error('Error fetching images:', error);
        });
    },
    selectImage(image) {
      console.log("selectImage")
      this.setSelectedImage(image);
      this.fetchWords(image["path"]);
    },
    fetchWords(imagePath) {
      axios.post(process.env.VUE_APP_BASE_URL + 'image_by_path', JSON.stringify({"path": imagePath}), {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(response => {
        let words = response.data["Annotations"].map(word => ({
          text: word,
          style: { top: '0px', left: '0px', display: "none" } // Initialize with default position
        }));
        this.setWords(words);
      })
      .catch(error => {
        console.error('Error fetching words:', error);
      });
    }
  }
}
</script>

<style>
#app {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

#loading-window {
  z-index: 1000 !important;
}

.container {
    max-width: 100%;
}
</style>