<template>
  <div ref="component" class="image-preview-wrapper">
    <v-img
      v-if="inView"
      :src="imageSrc"
      :alt="image.name"
      class="image-preview"
      @click="selectImage"
      :class="{ 'image-selected': selectedImage && selectedImage.id === image.id }"
      height="150"
      contain
    >
      <template v-slot:placeholder>
        <v-progress-circular indeterminate color="grey lighten-2"></v-progress-circular>
      </template>
    </v-img>
  </div>
</template>

<script>
export default {
  props: {
    image: Object, 
    selectedImage: Object
  },
  data() {
    return {
      inView: false,
      observer: null,
      visibilityThreshold: 1.0
    };
  },
  computed: {
    imageSrc() {
      return process.env.VUE_APP_BASE_URL + this.image.path;
    }
  },
  mounted() {
    this.$nextTick(() => {
      const componentElement = this.$refs.component;
      if (componentElement) {
        this.observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                this.inView = true;  // Image should be loaded now
                this.observer.unobserve(entry.target);  // Unobserve once the image is in view
              }
            });
          },
          {
            root: null,  // Use the viewport as root
            threshold: this.visibilityThreshold,  // Adjust when the image should load
          }
        );
        
        this.observer.observe(componentElement);  // Observe the component wrapper
      }
    });
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();  // Disconnect the observer when the component is destroyed
    }
  },
  methods: {
    selectImage() {
      this.$emit('image-selected', this.image);  // Emit event to parent when image is clicked
    }
  }
};
</script>

<style scoped>
.image-preview-wrapper {
  position: relative;
}

.image-preview {
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
  transition: transform 0.2s;
}

.image-preview:hover {
  transform: scale(1.05);
}

.image-selected {
  border: 2px solid #1f78b4;
  box-shadow: 0 0 10px rgba(31,120,180, 0.5);
}
</style>