<template>
  <div id="annotation-list">
  <v-container>
    <v-row >
      <v-range-slider
        v-model="sizeRange"
        :step="1"
        :min="0"
        :max="maxSliderValue"
        strict
        @change="filterBySize"
      ></v-range-slider>
    </v-row>
    <v-row align="center">
      <v-switch
        v-model="showPoints"
        color="primary"
        label="Show Points"
        class="toggle-switch"
        @change="onShowPointsToggle"
      />
      <v-col cols="auto" class="call-sam-col">
        <div>
          <v-btn :disabled="isSAMDisabled" @click="callSam">Segment Everything</v-btn>
        </div>
        <div>
          <v-btn :disabled="noPrompts" @click="sendPointData">Single Polygon Prompt</v-btn>
        </div>  
        <div>
          <v-btn :disabled="noPrompts" @click="sendPointDataMultiple">Multiple Polygon Prompt</v-btn>
        </div>
      </v-col> 
      <v-col  cols="auto">                 
        <div>
          <v-btn @click="callGSAM">Call Grounded SAM2</v-btn>
        </div>        
        <div>
          <v-btn @click="callRam">Get Annotations</v-btn>
        </div>             
        <div>
          <v-btn @click="exportAnnotation" style="margin-top: 5px;">Export</v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- Toggle Button for Advanced Options -->
    <v-row align="center">
      <v-col cols="auto" class="advanced-toggle-col">
        <v-btn icon @click="toggleAdvancedMenu">
          <v-icon>{{ advancedMenuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
        <span class="advanced-options-text" style="margin-left: 8px; font-size: 10px; color: #666;">Advanced Options</span>
      </v-col>
    </v-row>

    <!-- Advanced Options -->
    <v-row v-if="advancedMenuOpen">
      <v-col cols="auto" class="input-fields-col">
        <div>
          <div class="text-caption">IOU Threshold</div>
          <v-text-field
            v-model="iouThreshold"
            type="number"
            :min="0"
            :max="1"
            :step="0.01"
            @input="updateIouThreshold"
            class="small-input"
            dense
            hide-details
            style="margin-top: 10px;"
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="auto" class="input-fields-col">
        <div>
          <div class="text-caption">Stability Threshold</div>
          <v-text-field
            v-model="stabilityThreshold"
            type="number"
            :min="0"
            :max="1"
            :step="0.01"
            @input="updateStabilityThreshold"
            class="small-input"
            dense
            hide-details
            style="margin-top: 10px;"
          ></v-text-field>
        </div>
      </v-col>
      <div>  
        <v-btn @click="callTag2Text">Get Description</v-btn>
      </div>             
    </v-row>

    <!-- Annotations Section -->
    <v-row>
      <v-col>
        <div id="word-list">
          <div class="headline-text">
            Annotations
            <v-btn @click="addWord" small style="margin-left: 10px;">
              <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <path d="m17.5 12c3.0375661 0 5.5 2.4624339 5.5 5.5s-2.4624339 5.5-5.5 5.5-5.5-2.4624339-5.5-5.5 2.4624339-5.5 5.5-5.5zm0 1.9992349-.0898756.0080557c-.2040931.0370439-.3650248.1979756-.4020687.4020687l-.0080557.0898756-.0003502 2.5-2.502.0007651-.0898756.0080557c-.2040931.0370439-.3650248.1979756-.4020687.4020687l-.0080557.0898756.0080557.0898756c.0370439.2040931.1979756.3650248.4020687.4020687l.0898756.0080557 2.503-.0007651.0004578 2.5042498.0080557.0898756c.037044.2040931.1979757.3650248.4020687.4020687l.0898756.0080557.0898757-.0080557c.204093-.0370439.3650247-.1979756.4020687-.4020687l.0080556-.0898756-.0004578-2.5042498 2.5039157.0007651.0898756-.0080557c.2040931-.0370439.3650248-.1979756.4020687-.4020687l.0080557-.0898756-.0080557-.0898756c-.0370439-.2040931-.1979756-.3650248-.4020687-.4020687l-.0898756-.0080557-2.5049157-.0007651.0003502-2.5-.0080557-.0898756c-.0423359-.2332492-.2464844-.4101244-.4919443-.4101244zm-1.5-10.9992349c.5128358 0 .9355072.38604019.9932723.88337887l.0067277.11662113v2c0 .55228475-.4477153 1-1 1-.5128358 0-.9355072-.38604019-.9932723-.88337887l-.0067277-.11662113h-4v13l.1741301.000839c.1702136.7201511.4602191 1.3940057.8478199 1.9993673l-4.02195-.0002063c-.55228475 0-1-.4477153-1-1 0-.5128358.38604019-.9355072.88337887-.9932723l.11662113-.0067277h1v-13h-4c0 .51283584-.38604019.93550716-.88337887.99327227l-.11662113.00672773c-.51283584 0-.93550716-.38604019-.99327227-.88337887l-.00672773-.11662113v-2c0-.55228475.44771525-1 1-1 .51283584 0 .93550716.38604019.99327227.88337887l.00672773.11662113h10c0-.55228475.4477153-1 1-1z" fill="#212121"/>
              </svg>
            </v-btn>
          </div>
          <div v-for="word in words" :key="word.text" class="word-container">
            <div class="word" draggable="true" @dragstart="onDragStart(word, $event)">
              {{ word.text }}
            </div>
          </div>
        </div>
      </v-col>
      <v-col>
      <v-row v-for="(poly, index) in labeledCutouts" :key="index" class="align-center">
        <v-col>
            <img :src="poly.path" style="width: 50%; height: auto;" />
          </v-col>
          <v-col>
            <span>{{poly.label}}</span>
          </v-col>
      </v-row>
      </v-col>
    </v-row>
  </v-container>
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline"></v-card-title>
        <v-card-text>
          <v-menu
            v-model="showSuggestions"
            :close-on-content-click="false"
            max-width="300"
            offset-y
            attach
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="newWord"
                append-icon="mdi-magnify"
                label="Enter the new annotation:"
                autofocus
                v-bind="attrs"
                v-on="on"
                @input="fetchSuggestions"
              ></v-text-field>
            </template>
            <v-list v-if="suggestions.length" dense>
              <v-list-item
                v-for="suggestion in suggestions"
                :key="suggestion"
                @click="selectSuggestion(suggestion)"
              >
                <v-list-item-content>{{ suggestion }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="confirmAddWord">Add</v-btn>
          <v-btn @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
    <v-dialog v-model="ramDialog" max-width="400">
      <v-card max-height="600" class="overflow-y-auto">
        <v-card-title class="headline">Select Words</v-card-title>
        <v-card-text>
          <v-checkbox
            v-for="(word, index) in ramWords"
            :key="index"
            color="primary"
            hide-details
            v-model="word.selected"
            :label="word.text"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addSelectedWords">Add Selected</v-btn>
          <v-btn @click="ramDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
    <v-dialog v-model="gsamDialog" max-width="400">
      <v-card max-height="600" class="overflow-y-auto">
        <v-card-title>
          <span v-if="gsamPolygons.length > 0">Select Polygons</span>
          <span v-else>No Polygons Found</span>
        </v-card-title>

        <v-card-text v-if="gsamPolygons.length > 0" >
          <v-row v-for="(poly, index) in gsamPolygons" :key="index" class="align-center">
            <v-col cols="3">
              <img :src="poly.cutout" style="width: 100%; height: auto;" />
            </v-col>
            <v-col cols="9">
              <v-checkbox
                color="primary"
                hide-details
                v-model="poly.selected"
                :label="poly.label"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <p>No polygons available to select.</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="addSelectedPolygons"
            v-if="gsamPolygons.length > 0"
          >
            Add Selected
          </v-btn>
          <v-btn @click="gsamDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>   
  </div>
</template>


<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import axios from 'axios'
import { setWord, logging } from "./textHelper.js";

export default {
  props: ['images'],  
  data() {
    return {
      sizeRange: [0, 0],
      maxSliderValue: 100,
      iouThreshold: 0.8, // Default value for IOU Threshold
      stabilityThreshold: 0.92, // Default value for Stability Threshold
      dialog: false,
      newWord: '',
      ramDialog: false,
      gsamDialog: false, 
      ramWords: [],
      gsamPolygons: [],
      showPoints: false,
      suggestions: [],
      showSuggestions: false,
      isSAMDisabled: false,
      noPrompts: true,
      vocab: [],
      labeledCutouts: [],
      advancedMenuOpen: false, // Control visibility of advanced options
    };
  },
  mounted() {
    // Initialize vocab when component is mounted
    this.updateVocab(this.images);
  },    
  computed: {
    ...mapState(['words', 'loggingMode', 'selectedImage', 'filterRange', 'pointPrompts', 'polygons', 'savedMasks', 'imagePolygonLookup']),
    ...mapGetters(['drawnWordsCount'])
  },
  methods: {
    ...mapActions(['setLoading', 'toggleFilter', 'saveMask', 'clearPointPrompts', 'togglePoints', 'drawnWords', 'addDrawWord', 'setDraggedWord', 'addPolygon', 'setPolygons', 'setRange', 'addPolygonsToLookup']),
    toggleAdvancedMenu() {
      this.advancedMenuOpen = !this.advancedMenuOpen; // Toggle advanced options
      if (this.loggingMode) {
        logging({"info": "toggleAdvancedMenu", "advancedMenuOpen": this.advancedMenuOpen})
      }    
    },
    onShowPointsToggle() {
      if (this.loggingMode) {
        logging({"info": "togglePoints", "showPoints": this.showPoints})
      }
      this.togglePoints(this.showPoints)
    },
    onDragStart(word, event) {
      const rect = event.target.getBoundingClientRect();
      const offsetX = event.clientX - rect.left;
      const offsetY = event.clientY - rect.top;
      if (this.loggingMode) {
        logging({"info": "startDrag", "text":word.text, "isNew": true, "id": this.drawnWordsCount, "offsetX": offsetX, "offsetY": offsetY})
      }
      this.setDraggedWord({"text":word.text, "isNew": true, "id": this.drawnWordsCount, "offsetX": offsetX, "offsetY": offsetY});
    },
    callSam() {
      if (this.loggingMode) {
        logging({"info": "callSAM", "path": this.selectedImage["path"], 
          "iou_thresh": this.iouThreshold, "stability_score_thresh": this.stabilityThreshold})
      }
      //use allready saved polygons
      if (this.selectedImage["path"] in this.imagePolygonLookup) {
        //TODO also reuse labels
        this.isSAMDisabled = true;
        this.addPolygon(this.imagePolygonLookup[this.selectedImage["path"]]); 
        return
      }
      this.setLoading(true);
      axios.post(process.env.VUE_APP_BASE_URL + 'call_sam', JSON.stringify({
        "path": this.selectedImage["path"], "iou_thresh": this.iouThreshold, 
        "stability_score_thresh": this.stabilityThreshold, "polygons": this.polygons}), {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(response => {
        this.setLoading(false);
        this.isSAMDisabled = true;
        const polygons = response.data.polygons.sort((a, b) => b.size - a.size)
        this.addPolygon(polygons); 
        this.addPolygonsToLookup({"img": this.selectedImage["path"], "polygons": polygons})
      })
      .catch(error => {
        this.setLoading(false);
        console.error('Error fetching words:', error);
      });
    },
    callGSAM() {
      const tags = this.words.map(x => x.text).join(" | ")
      if (this.loggingMode) {
        logging({"info": "callGSAM", "path": this.selectedImage["path"], "tags": tags})
      }
      this.setLoading(true);
      axios.post(process.env.VUE_APP_BASE_URL + 'call_grounded_sam', JSON.stringify({
        "path": this.selectedImage["path"], "tags": tags}), {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(response => {
        this.setLoading(false);
        this.gsamPolygons = response.data.polygons
        this.gsamDialog = true
      })
      .catch(error => {
        this.setLoading(false);
        console.error('Error fetching words:', error);
      });
    },    
    callRam() {
      if (this.loggingMode) {
        logging({"info": "callRAM", "path": this.selectedImage["path"]})
      }
      this.setLoading(true);
      axios.post(process.env.VUE_APP_BASE_URL + 'call_ram_plus', JSON.stringify({
        "path": this.selectedImage["path"]}), {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(response => {
        this.setLoading(false);
        this.ramWords = response.data.tags.split(" | ").map(word => ({ text: word, selected: false }));
        this.ramDialog = true; 
      })
      .catch(error => {
        this.setLoading(false);
        console.error('Error fetching words:', error);
      });
    }, 
    sendPointData() {
      if (this.pointPrompts.length === 0) {
        return
      }
      if (this.loggingMode) {
        logging({"info": "call_sam_with_point", "path": this.selectedImage["path"], 
        "points": this.pointPrompts})
      }
      this.setLoading(true);
      axios.post(process.env.VUE_APP_BASE_URL + 'call_sam_with_point', JSON.stringify({ "path": this.selectedImage["path"], 
        "points": this.pointPrompts}), {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(response => {
        this.setLoading(false);
        this.addPolygon(response.data.polygons[0]);
        this.clearPointPrompts();
      })
      .catch(error => {
        this.setLoading(false);
        console.error('Error calling SAM with BB:', error);
      });
    }, 
    sendPointDataMultiple() {
      if (this.pointPrompts.length === 0) {
        return
      }
      if (this.loggingMode) {
        logging({"info": "call_sam_with_point_multiple", "path": this.selectedImage["path"], 
        "points": this.pointPrompts})
      }

      this.setLoading(true);      
      axios.post(process.env.VUE_APP_BASE_URL + 'call_sam_with_point_multiple', JSON.stringify({ "path": this.selectedImage["path"], 
        "points": this.pointPrompts}), {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(response => {
        this.setLoading(false);
        for (let poly of response.data.polygons) {
          this.addPolygon(poly)
        }
        this.clearPointPrompts();
      })
      .catch(error => {
        this.setLoading(false);
        console.error('Error calling SAM with BB:', error);
      });
    },         
    callTag2Text() {
      const tags = this.words.map(x => x.text).join(" | ")
      if (this.loggingMode) {
        logging({"info": "callTag2Text", "path": this.selectedImage["path"], "tags": tags})
      }
      this.setLoading(true);
      axios.post(process.env.VUE_APP_BASE_URL + 'call_tag2text', JSON.stringify({
        "path": this.selectedImage["path"], "tags": tags}), {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(response => {
        this.setLoading(false);
        prompt(response.data.result[2]);

      })
      .catch(error => {
        this.setLoading(false);
        console.error('Error fetching words:', error);
      });
    },        
    exportAnnotation() {
      if (this.savedMasks.length === 0) {
        return
      }
      if (this.loggingMode) {
        logging({"info": "export"})
      }

      this.setLoading(true);
      let jsonObject = this.savedMasks
      let name = "annotated-data.json"
      // need to save the masks, classes and images
  
      // Step 2: Convert the JSON object to a string
      const jsonString = JSON.stringify(jsonObject, null, 2); // The second argument adds indentation for readability

      // Step 3: Create a Blob object with the JSON string
      const blob = new Blob([jsonString], { type: "application/json" });

      // Step 4: Create a URL for the Blob object
      const url = URL.createObjectURL(blob);

      // Step 5: Create a link element and set its href attribute to the URL
      const a = document.createElement('a');
      a.href = url;
      a.download = name + '.json'; // The file name to be saved

      // Step 6: Programmatically click the link to trigger the download
      document.body.appendChild(a);
      a.click();

      // Step 7: Clean up by revoking the object URL and removing the link element
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
      this.setLoading(false);
    },
    showCutouts() {
      //TODO need this logging?
      let data = []
      for (let mask of this.savedMasks.filter(x => x["image"] === this.selectedImage["path"])) {
        data.push({"label": mask["annotation"], "path": mask["image"], "points": mask["polygon"]["points"]})
      }
      axios.post(process.env.VUE_APP_BASE_URL + 'get_labeled_cutout_images', JSON.stringify({ "images": data}), {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(response => {
        this.labeledCutouts = response.data.paths
      })
      .catch(error => {
        this.setLoading(false);
        console.error('Error calling get cutout', error);
      });
    },
    filterBySize() {
      if (this.loggingMode) {
        logging({"info": "filterBySize", "sizeRange": this.sizeRange})
      }
      this.setRange(this.sizeRange)
      this.toggleFilter();
    },
    updateIouThreshold(value) {
      if (this.loggingMode) {
        logging({"info": "updateIouThreshold", "value": value})
      }
      value = parseFloat(value);
      if (!isNaN(value) && value >= 0 && value <= 1) {
        this.iouThreshold = value;
      }
    },
    updateStabilityThreshold(value) {
      if (this.loggingMode) {
        logging({"info": "updateStabilityThreshold", "value": value})
      }
      value = parseFloat(value);
      if (!isNaN(value) && value >= 0 && value <= 1) {
        this.stabilityThreshold = value;
      }
    },
    addWord() {
      this.dialog = true; // Open the dialog
    },
    addSelectedWords() {
      const logWords = []; 
      // Add the selected words to the annotations
      this.ramWords.forEach(word => {
        if (word.selected) {
          this.words.push({ text: word.text });
          logWords.push(word.text)
        }
      });
      if (this.loggingMode) {
        logging({"info": "addSelectedRAMWords", "words": logWords})
      }
      this.ramDialog = false; // Close the dialog
    },    
    confirmAddWord() {
      if (this.newWord.trim()) {
        if (this.loggingMode) {
          logging({"info": "addWord", "word": this.newWord})
        } 
        this.words.push({ text: this.newWord.trim() });
        this.newWord = ''; // Clear the input field
      }
      this.dialog = false; // Close the dialog
    },
    addSelectedPolygons() {
      const logPolygons = []
      this.gsamPolygons.forEach(poly => {
        if (poly.selected) {
          const wordId = this.drawnWordsCount;
          this.addPolygon(poly);
          const word = setWord(poly.points, poly.label, wordId, this.selectedImage)
          this.addDrawWord(word);
          logPolygons.push(poly)
        }
      });
      if (this.loggingMode) {
        logging({"info": "addSelectedPolygon", "labeledPolygons": logPolygons})
      }
      this.gsamDialog = false; // Close the dialog
    },
    selectSuggestion(suggestion) {
      this.newWord = suggestion;
      this.suggestions = [];
      this.showSuggestions = false;
    }, 
    updateVocab(data) {
      const vocabSet = new Set();
      
      data.forEach(image => {
        image["Annotations"].forEach(annotation => {
          vocabSet.add(annotation);
        });
      });

      this.vocab = Array.from(vocabSet).sort(); // Create a sorted list of unique annotations
    },   
    fetchSuggestions() {
      if (!this.newWord) {
        this.suggestions = [];
        this.showSuggestions = false;
        return;
      }

      const searchLower = this.newWord.toLowerCase();
      this.suggestions = this.vocab.filter(annotation =>
        annotation.toLowerCase().includes(searchLower)
      ).slice(0, 10); // Limit suggestions for performance
      this.showSuggestions = this.suggestions.length > 0;
    },            
  },
  watch: {
    savedMasks: {
      handler(val, prev) {
        if (val.length !== prev.length) {
          this.showCutouts();
        } else if(val.length === prev.length) { //drag and drop to another polygon
          // Check if all hitindex values are the same
          const hasChanges = val.some((v, i) => v.hitIndex !== prev[i].hitIndex);
          if (hasChanges) {
            this.showCutouts();
          }
        }
      }
    },
    selectedImage: {
      handler(val, prev) {
        if (val["path"] !== prev["path"]) {
          this.isSAMDisabled = false;
          this.labeledCutouts = [];
        }
      }
    },
    pointPrompts: {
      handler(val) {
        if (val.length > 0) {
          this.noPrompts = false;
        } else {
          this.noPrompts = true;
        }
      }
    },
    images: {
      handler(newImages) {
        this.updateVocab(newImages);
      },
      deep: true,
    },
    filterRange: {
      handler(val) {
        this.maxSliderValue = this.polygons.length
        this.sizeRange = val
      }
    }
  }   
}

</script>

<style scoped>

.headline-text {
  font-size: 20px;
}

.call-sam-col {
  flex: none; /* Ensures this column doesn't grow and takes only the necessary space */
}

.input-fields-col {
  flex: none; /* Ensures this column doesn't grow and takes only the necessary space */
}

.size-slider {
  width: 100%; /* Makes the range slider expand to fill the available space */
}
.small-input {
  width: 100px; /* Fixed width for the small input fields */
}

#annotation-list {
  border: 2px solid #333; /* Solid border with color */
  border-radius: 10px;    /* Smooth rounded corners */
  padding: 10px;          /* Padding inside the word list */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
  background-color: #fff; /* Optional background color */
  height: 95vh; /* Full viewport height */
  overflow-y: auto; /* Scrollable content when overflowing */
}

.word-container {
  padding: 5px;          /* Padding inside the word list */
}

.word-list {
  display: flex;
  flex-direction: column; /* Ensures words are stacked vertically */
}

.word {
  display: inline-block; /* Make the word container fit the length of the word */
  padding: 10px;         /* Padding inside each word item */
  background-color: #e0e0e0; /* Background color for word items */
  border: 1px solid #ccc; /* Border around each word item */
  border-radius: 5px;    /* Rounded corners for word items */
  cursor: move;          /* Cursor to indicate draggable item */
  font-size: 14px;       /* Optional: Font size adjustment */
  font-family: Arial, sans-serif; /* Optional: Font family */
}
.v-menu__content {
  z-index: 1000; /* Ensure dropdown appears above other elements */
}


</style>

<style>
.v-dialog {
  overflow: visible !important; /* Allow overflow for the dialog */
}
.v-slider__thumb-label{
  transform: translateY(50%) translateY(24px) translateX(-50%) rotate(225deg) !important;
}

.v-slider__thumb-label div span{
            display: inline-block; /* Ensure the span respects transform properties */
            transform: scale(-1, -1); /* Flip text horizontally */
            direction: rtl; /* Optional: To ensure text reads correctly */
            /* Adjust text alignment if needed */
            text-align: center; /* Center text horizontally */
}
</style>