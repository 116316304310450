import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    draggedWord: null,
    words: [], 
    drawnWords: [],
    polygons: [],
    filterRange: [0, 0],
    savedMasks: [],
    pointPrompts: [],
    togglePoints: false,
    loading: false,
    isFiltered: false,
    selectedImage: {"path": ""},
    loggingMode: true,
    imagePolygonLookup: {} //save masks for an image in a dict (load from there when needed)
  },
  mutations: {
    setDraggedWord(state, word) {
      state.draggedWord = word;
    },
    updatePolygon(state, { polygonIndex, polygon }) {
      state.polygons.splice(polygonIndex, 1, polygon); // Update the polygon in the state
      //add, update and remove polygon also update the reference to the lookup
    },
    updatePolygonPoints(state, {polygonIndex, points}) {
      state.polygons[polygonIndex].points = points;
    },
    setSelectedImage(state, image) {
      state.selectedImage = image;
    },
    removePolygon(state, polygonIndex) {
      //the first option triggers no update - this is a problem because we use the index that is assigned in the draw method
      // if this is computational too expensive, we can use the first option and do the index in another way
      //state.polygons.splice(polygonIndex, 1);
      state.polygons = state.polygons.filter((_, index) => index !== polygonIndex); 
      // Update `hitIndex` in savedMasks
      state.savedMasks.forEach(mask => {
          if (mask["hitIndex"] > polygonIndex) {
              // Decrement hitindex if it's greater than the removed polygon index
              mask["hitIndex"] -= 1;
          }
      });

      state.filterRange = [0, state.polygons.length];
    },
    updateWord(state, {wordId, word}) {
      const index = state.drawnWords.findIndex(x => x.id === wordId) 
      state.drawnWords[index] = word;
    },   
    setWords(state, words) {
      state.words = words;
    },
    setPolygons(state, polygons) {
      state.polygons = polygons;

      state.filterRange = [0, polygons.length];
    },
    toggleFilter(state) {
      state.isFiltered = !state.isFiltered;
    },
    setDrawnWords(state, words) {
      state.drawnWords = words;
    },
    setRange(state, range) {
      state.filterRange = range;
    },
    saveMask(state, obj) {
      state.savedMasks = [...state.savedMasks, obj];
    },
    removeMask(state, mask) {
      const maskIndex = state.savedMasks.findIndex(x => x.hitIndex === mask.hitIndex && x.annotation === mask.annotation) 
      state.savedMasks = state.savedMasks.filter((_, index) => index !== maskIndex); 
    },
    removeWord(state, wordId) {
      const index = state.drawnWords.findIndex(x => x.id === wordId) 
      state.drawnWords.splice(index, 1)
    },    
    addPolygon(state, obj) {
      // to ensure reactivity
      let newPolygons = Array.isArray(obj) 
        ? [...state.polygons, ...obj]  // Merge if `obj` is an array
        : [...state.polygons, obj];  

      // map each element to include its original index
      let indexedPolygons = newPolygons.map((item, index) => ({
          originalIndex: index,
          item: item
      }));

      // Sort by size in descending order
      // paint in order based on size so that big elements are in the background)
      indexedPolygons.sort((a, b) => b.item.size - a.item.size);

      // Create a lookup object with the old index as the key and the new index as the value
      //TODO this only works when working on one image but when loading things from another image
      let indexLookup = {};
      indexedPolygons.forEach((entry, newIndex) => {
          indexLookup[entry.originalIndex] = newIndex;
      });

      newPolygons = indexedPolygons.map(x => x.item)
      // need to change all hitIndex in savedMasks
      for (let i = 0; i < state.savedMasks.length; i++) {
        if (state.savedMasks[i]["image"] !== state.selectedImage["path"]) {
          //skip other images
          continue
        }
        if (indexLookup[state.savedMasks[i]["hitIndex"]]) {
          state.savedMasks[i]["hitIndex"] = indexLookup[state.savedMasks[i]["hitIndex"]]
        } else {
          //when loading things from another image
          state.savedMasks[i]["hitIndex"] = i
        }
        console.log(state.savedMasks[i]["hitIndex"], indexLookup, state.savedMasks[i]["hitIndex"])
      }
      state.polygons = newPolygons
      state.filterRange = [0, newPolygons.length];
    },
    addPolygonsToLookup(state, obj) {
      state.imagePolygonLookup[obj.img] = obj.polygons
    },
    addPointPrompts(state, obj) {
      state.pointPrompts.push(obj);
    },
    clearPointPrompts(state) {
      state.pointPrompts = []
    },
    addDrawWord(state, word) {
      state.drawnWords = [...state.drawnWords, word]
    },
    togglePoints(state, val) {
      state.togglePoints = val;
    },
    setLoading(state, val) {
      state.loading = val;
    }
  },
  actions: {
    setSelectedImage({ commit }, image) {
      commit('setSelectedImage', image);
    },
    setDraggedWord({ commit }, word) {
      commit('setDraggedWord', word);
    },
    setLoading({ commit }, val) {
      commit('setLoading', val);
    },
    setWords({ commit }, words) {
      commit('setWords', words);
    }, 
    setPolygons({commit}, polygons) {
      commit('setPolygons', polygons);
    },
    setDrawnWords({commit}, words) {
      commit('setDrawnWords', words);
    },    
    setRange({commit}, filterRange) {
      commit('setRange', filterRange);
    },
    updateWord({commit}, {wordIndex, word}) {
      commit('updateWord', {wordIndex, word})
    },
    updatePolygon({commit}, { polygonIndex, polygon }) {
      commit('updatePolygon', { polygonIndex, polygon })
    },
    updatePolygonPoints({commit}, {polygonIndex, points}) {
      commit('updatePolygonPoints', {polygonIndex, points})
    },
    saveMask({commit}, obj) {
      commit('saveMask', obj)
    },
    addPolygon({commit}, obj) {
      commit('addPolygon', obj)
    },
    removeMask({commit}, mask) {
      commit('removeMask', mask)
    },
    removeWord({commit}, index) {
      commit('removeWord', index)
    },    
    addPolygonsToLookup({commit}, obj) {
      commit('addPolygonsToLookup', obj)
    },
    removePolygon({commit}, index) {
      commit('removePolygon', index)
    },
    addPointPrompts({commit}, obj) {
      commit('addPointPrompts', obj)
    },
    clearPointPrompts({commit}) {
      commit('clearPointPrompts')
    },
    addDrawWord({commit}, word) {
      commit('addDrawWord', word)
    },    
    togglePoints({commit}, val) {
      commit('togglePoints', val)
    },
    toggleFilter({commit}) {
      commit('toggleFilter')
    }        
  },
  getters: {
    drawnWordsCount(state) {
      return state.drawnWords.length;
    }
  }
});
